import React from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  DateField,
  Show, TabbedShowLayout, Tab, ReferenceManyField,Pagination,
  RadioButtonGroupInput,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput,
} from 'react-admin'
import BooleanField from "src/components/fields/BooleanField";
import FeedBackRateField from "src/components/fields/FeedBackRateField";
import NameWithImageField from "src/components/fields/NameWithImageField";
import ProfileField from "src/components/fields/ProfileField";

import DateRangeInput from "src/components/inputs/DateRangeInput";
import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import RelatedList from "src/components/list/RelatedList";
import Countries from "src/data/countries";
import { MarksList } from "src/data/marks";
import { getCategoryTranslation } from "src/utils/translations";

const FeedbackFilter = (props) => (
  <Filter {...props}>
    <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Date Range"  alwaysOn={true} />
    <SelectInput source="target" multiline={true} label={'Target'}  choices={[{id: 'site', name: 'Site'}, {id: 'master', name: 'Master'}, {id: 'client', name: 'Client'}]} fullWidth={true} variant={'outlined'} alwaysOn={true}/>

    <TextInput source={'id'} label={'ID'} alwaysOn={true} variant={'outlined'}/>
    <ReferenceInput label="Author" source="fromProfileId" reference="profile" variant={'outlined'}
                    filter={{ role: 'client' }}
                    filterToQuery={searchText => ({ ...(searchText ? { 'firstName||$contL&lastName||$contL': searchText } : {}) })}
                    sort={{ field: 'lastName', order: 'DESC' }}>
      <AutocompleteInput optionText={record => `${record.firstName} ${record.lastName}`} />
    </ReferenceInput>
    <ReferenceInput label="To Profile" source="toProfileId" reference="profile" variant={'outlined'}
                    filter={{ role: 'client' }}
                    filterToQuery={searchText => ({ ...(searchText ? { 'firstName||$contL&lastName||$contL': searchText } : {}) })}
                    sort={{ field: 'lastName', order: 'DESC' }}>
      <AutocompleteInput optionText={record => `${record.firstName} ${record.lastName}`} />
    </ReferenceInput>

    <ReferenceInput label="Task" source="taskId" reference="task" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'id||$eq': searchText } : {}) })}
                    sort={{ field: 'id', order: 'ASC' }}>
      <AutocompleteInput optionText={record => `#${record.id} ${record.title}`} />
    </ReferenceInput>

  </Filter>
)

export const FeedbackList = (props) => (
  <List
    {...props}
    title="Feedback list"
    filters={<FeedbackFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'ASC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Feedbacks not found'}/>}
  >
    <Datagrid rowClick={'show'}>
      <TextField source="id" label={'ID'}/>
      <TextField source="target" label={'Target'}/>
      <FeedBackRateField source={'rate'} label={'Rating'}/>
      <TextField source="description" label={'Description'}/>

      <DateField source="createdAt" showTime={true} label={'Created'}/>
      <BooleanField source={'showInFirstPage'} label={'Main page'}/>
      <ReferenceField label="Author" source="fromProfileId" reference="profile" link={'show'}>
        <ProfileField source="firstName" />
      </ReferenceField>
      <ReferenceField label="To" source="toProfileId" reference="profile" link={'show'}>
        <ProfileField source="firstName" />
      </ReferenceField>
      <ReferenceField label="Task" source="taskId" reference="task" link={'show'}>
        <ProfileField source="firstName" />
      </ReferenceField>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Feedback: #{record.id}
    </span>
  )
}
export const FeedbackShow = (props) => (
    <Show {...props} title={<TitleEdit/>}>
      <TabbedShowLayout>
        <Tab label="Offers" path="">

        </Tab>
      </TabbedShowLayout>
    </Show>
)



const Form = ({...rest}) => {

  return (<>
    <ReferenceInput label="Task" source="taskId" reference="task" variant={'outlined'} fullWidth={true}
                    filterToQuery={searchText => ({ ...(searchText ? { 'id||$eq': searchText} : {}) })}
                    sort={{ field: 'id', order: 'DESC' }}>
      <AutocompleteInput optionText={record => `#${record.id} ${record.title}`} />
    </ReferenceInput>
    <ReferenceInput label="Author1" source="fromProfileId" reference="profile" variant={'outlined'} fullWidth={true} validate={required()}
                    filterToQuery={searchText => ({ ...(searchText ? { 'id||$eq': searchText} : {}) })}
                    sort={{ field: 'id', order: 'DESC' }}>
      <AutocompleteInput optionText={record => `#${record.id} ${record.firstName} ${record.lastName} (${record.role})`} />
    </ReferenceInput>
    <ReferenceInput label="ToProfile" source="toProfileId" reference="profile" variant={'outlined'} fullWidth={true}
                    filterToQuery={searchText => ({ ...(searchText ? { 'id||$eq': searchText} : {}) })}
                    sort={{ field: 'id', order: 'DESC' }}>
      <AutocompleteInput optionText={record => `#${record.id} ${record.firstName} ${record.lastName} (${record.role})`} />
    </ReferenceInput>
    <BooleanInput source="showInFirstPage" multiline={true} label={'Show on the Main page'} />

    <SelectInput source="target" multiline={true} label={'Target'}  choices={[{id: 'site', name: 'Site'}, {id: 'master', name: 'Master'}, {id: 'client', name: 'Client'}]} variant={'outlined'} fullWidth={true}  validate={required()}/>


    <FormDataConsumer>
      {({ formData, ...rest }) => formData.target === 'site' && (
          <>
            <SelectInput {...rest} source={'usability'} label={'Usability'} choices={MarksList} variant={'outlined'} fullWidth={true}  />
            <SelectInput {...rest} source={'masterSearchSpeed'} label={'Master search speed'} choices={MarksList} variant={'outlined'} fullWidth={true}  />
            <SelectInput {...rest} source={'willUseAgain'} label={'Will use Again'} choices={MarksList} variant={'outlined'} fullWidth={true}  />
            <SelectInput {...rest} source={'willReccommend'} label={'Will reccommend'} choices={MarksList} variant={'outlined'} fullWidth={true}  />
          </>
      )}
    </FormDataConsumer>

    <FormDataConsumer>
      {({ formData, ...rest }) => ['master', 'client'].includes(formData.target)  && (
          <>
            <SelectInput {...rest} source={'workQuality'} label={'Work quality'} choices={MarksList} variant={'outlined'} fullWidth={true}  />
            <SelectInput {...rest} source={'politeness'} label={'Politeness'} choices={MarksList} variant={'outlined'} fullWidth={true}  />
            <SelectInput {...rest} source={'deadlines'} label={'Deadlines'} choices={MarksList} variant={'outlined'} fullWidth={true}  />
            <SelectInput {...rest} source={'extraExpenses'} label={'Extra Expenses'} choices={MarksList} variant={'outlined'} fullWidth={true}  />
            <SelectInput {...rest} source={'reccommend'} label={'Reccommend'} choices={MarksList} variant={'outlined'} fullWidth={true}  />
          </>
      )}
    </FormDataConsumer>

    <TextInput source="description" multiline={true} label={'Description'} variant={'outlined'} fullWidth={true}  validate={required()}/>


  </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const FeedbackEdit = (props) => (
    <Edit {...props} title={<TitleEdit/>} undoable={false}>
      <SimpleForm redirect={'list'}>
        <Form {...props}/>
      </SimpleForm>
    </Edit>
)

export const FeedbackCreate = (props) => {
  return(
      <Create title="Создать отзыв" {...props}>
        <SimpleForm redirect={'list'} >
          <Form {...props}/>
        </SimpleForm>
      </Create>
  )}

