import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ReferenceArrayInput,
  AutocompleteInput,
  ReferenceInput,
  FunctionField,
  ListProps,
  AutocompleteArrayInput,
  BooleanField,
  ArrayField,
  Datagrid,
  ReferenceField,
  ChipField,
  SingleFieldList,
  SelectInput,
  TextField,
  SelectField,
  BooleanInput, DateField, SimpleForm, Tab
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import EmptyList from 'src/components/list/EmptyList'
import {OrganizationStatusList} from 'src/types'

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="name||$contL" label={'Search'} variant={'outlined'} alwaysOn={true} resettable={true}/>
  </Filter>
)

const OrganizationList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Organizations"
      sort={{field: 'id', order: 'DESC'}}
      empty={<EmptyList title={'No organizations'} description={'You can add organization'} buttonText={'Add Organization'}/>}
    >
    <Datagrid>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <TextField source={'name'} label={'Name'}/>
      <FunctionField source={'corporateProfile.lastName'} label={'Name'} render={i => `${i.corporateProfile?.firstName ?? ''} ${i.corporateProfile?.lastName ?? ''}`}/>
      <TextField source={'phone'} label={'Phone'} />
      <TextField source={'site'} label={'Site'} />
      <TextField source={'corporateProfile.email'} label={'Email'} />
      <DateField source={'createdAt'} label={'Created'} />
    </Datagrid>
    </List>
  );
}

export default OrganizationList;
