import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request'
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  HttpError,
} from 'react-admin'

function covertEmptyValues(obj: any) {
  Object.keys(obj).forEach(function(key) {
    if (obj[key] && typeof obj[key] === 'object') covertEmptyValues(obj[key]); //recursive for objects
    else if ( obj[key] === ""  && typeof obj[key] === 'string')  obj[key] = null;         //remove empty properties
  });
  return obj;
};

export default (apiUrl: string, httpClient: (url: string, params: any) => Promise<any>) => {
  const composeFilter = (paramsFilter: any) => {
    let paramsFilterObj = paramsFilter

    if (paramsFilterObj === '' || (typeof paramsFilterObj.q !== 'undefined' && paramsFilterObj.q === '')) {
      paramsFilterObj = {}
    }

    const flatFilter = fetchUtils.flattenObject(paramsFilterObj)
    console.log("flatFilter", paramsFilter);


    const filter = Object.keys(flatFilter).map(key => {


      const splitKeyOr = key.split('&')
      let res: any = {};
      for(const keySplit of splitKeyOr){
        const splitKey = keySplit.split('||')
        const ops = splitKey[1] ? splitKey[1] : 'eq'
        let field = splitKey[0]
        if (field.indexOf('_') === 0 && field.indexOf('.') > -1) {
          field = field.split(/\.(.+)/)[1]
        }
        console.log("key", key, splitKey, ops, field)
        const currentFilter = {field, operator: ops, value: flatFilter[key]};
        if(splitKeyOr.length > 1){
          if(!res["$or"]){
            res["$or"] = [];
          }
          res["$or"].push(currentFilter)
        }else{
          res = currentFilter
        }

      }

      return res;
    })
    console.log("Filter", filter)
    return filter
  }

  const convertDataRequestToHTTP = (type: string, resource: string, params: any) => {
    let url = ''
    const options: any = {}
    console.log('convertDataRequestToHTTP', params)
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination
        const filter: any = composeFilter(params.filter)


        const qb = RequestQueryBuilder.create();
        for(const item of filter){
          console.log("item", item)
          if(item["$or"]){
            qb.setOr(item["$or"])
          }else{

            qb.setFilter(item);
          }
        }
        const query = qb.setLimit(perPage)
          .setPage(page)
          .sortBy(params.sort)
          .setOffset((page - 1) * perPage)
          .query()

        url = `${apiUrl}/${resource}?${query}`

        break
      }
      case GET_ONE: {
        url = `${apiUrl}/${resource}/${params.id}`

        break
      }
      case GET_MANY: {
        const query = RequestQueryBuilder
          .create()
          .setFilter({
            field: 'id',
            operator: CondOperator.IN,
            value: `${params.ids}`,
          })
          .query()

        url = `${apiUrl}/${resource}?${query}`

        break
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination
        const filter: any = composeFilter(params.filter)

        filter.push({
          field: params.target,
          operator: CondOperator.EQUALS,
          value: params.id,
        })

        const query = RequestQueryBuilder
          .create({
            filter,
          })
          .sortBy(params.sort)
          .setLimit(perPage)
          .setOffset((page - 1) * perPage)
          .query()

        url = `${apiUrl}/${resource}?${query}`

        break
      }
      case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'PUT'
        if (params.data instanceof FormData) {
          options.body = covertEmptyValues(params.data)
        } else {
          options.body = JSON.stringify(covertEmptyValues(params.data))
        }
        break
      }
      case CREATE: {
        url = `${apiUrl}/${resource}`
        options.method = 'POST'

        if (params.data instanceof FormData) {
          console.log('O1', params.data)
          options.body = covertEmptyValues(params.data)
        } else {
          console.log('O2', params.data, JSON.stringify(covertEmptyValues(params.data)))
          options.body = JSON.stringify(params.data)
        }
        break
      }
      case DELETE: {
        console.log("Delete params", params)
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'DELETE'
        break
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  const convertHTTPResponse = (response: any, type: string, resource: string, params: any) => {
    const { headers, json } = response
    console.log('ManyReference', type, json)
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
      case GET_MANY:

        return {
          data: json.data || json,
          total: json.total,
        }
      case CREATE:

        return { data: { ...params.data, id: json.id } }
      case DELETE:

        return { data: { } }

      default:
        return { data: json }
    }
  }

  return (type: string, resource: string, params: any) => {

    console.log("QUERY", type, resource, params)
    if (type === 'move') {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify({sort: params.sort}),
        })
        .then(response => ({
          data: response.json
        }))
    }

    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id: string) => httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })),
      )
        .then(responses => ({
          data: responses.map((response: any) => response.json),
        }))
    }
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id: string) => httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })),
      ).then(responses => ({
        data: responses.map((response: any) => response.json),
      }))
    }

    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params,
    )
    return httpClient(url, options).then(
      response => convertHTTPResponse(response, type, resource, params),
    )
  }
}
