import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  Edit,
  EditButton,
  Tab,
  SelectField,
  EditProps,
  FormTab,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput,
  FunctionField,
  SimpleShowLayout,
  Show, BooleanField, TabbedShowLayout, TopToolbar, CreateButton
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';


import {Box, Typography} from '@material-ui/core';
import {OrderChangeStatusButton} from 'src/resources/organization/OrganizationChangeStatus'
import {OrganizationStatus, OrganizationStatusList} from 'src/types'

export const createStyles = {
  price: {width: '7em'},
  width: {width: '7em'},
  height: {width: '7em'},
  stock: {width: '7em'},
  widthFormGroup: {display: 'inline-block'},
  heightFormGroup: {display: 'inline-block', marginLeft: 32},
};

interface ProductTitleProps {
  record?: any;
}

const ProductTitle: FC<ProductTitleProps> = ({record}) =>
  record ? <span>Organization {record.name} </span> : null;

const useStyles = makeStyles({
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '40em',
    display: 'block',
  },
});

const Info = (props: any) => {

  const data = [];
  return (
    <>


    </>
  )
}

const ShowActions: FC<any> = (props) => (
  <TopToolbar>

    {[OrganizationStatus.Rejected, OrganizationStatus.Moderation].includes(props.data?.status) && <OrderChangeStatusButton status={OrganizationStatus.Approved} {...props}/>}
    {[OrganizationStatus.Approved, OrganizationStatus.Moderation].includes(props.data?.status) && <Box ml={3}>
    <OrderChangeStatusButton status={OrganizationStatus.Rejected} {...props}/>
    </Box>}
  </TopToolbar>
);
const OrganizationShow: FC<EditProps> = props => {

  const classes = useStyles();
  return (
    <Show {...props} title={<ProductTitle/>} actions={<ShowActions/>}>
      <TabbedShowLayout>
        <Tab label={'Info'}>
          <TextField source={'name'} label={'Name'}/>
          <SelectField source={'status'} label={'Status'} choices={OrganizationStatusList}/>
          <TextField source={'phone'} label={'Phone'} />
          <TextField source={'site'} label={'Site'} />
          <TextField source={'corporateProfile.email'} label={'Email'} />
          <TextField source={'corporateProfile.firstName'} label={'First Name'} />
          <TextField source={'corporateProfile.lastName'} label={'Last Name'} />
          <TextField source={'corporateProfile.jobTitle'} label={'JobTitle'} />
          <TextField source={'location.geoname.name'} label={'Country'} />
          <TextField source={'location.region'} label={'Province'} />
          <TextField source={'location.city'} label={'City'} />
          <TextField source={'location.zipcode'} label={'Postal code'} />
          <TextField source={'location.address'} label={'Address'} />
          <TextField source={'location.office'} label={'Office'} />
          <DateField source={'createdAt'} label={'Created'} />
        </Tab>

      </TabbedShowLayout>
    </Show>
  );
};

const requiredValidate = [required()];

export default OrganizationShow;