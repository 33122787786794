import React from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  DateField,
  Show, TabbedShowLayout, Tab, ReferenceManyField,Pagination,
  RadioButtonGroupInput,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput,
} from 'react-admin'
import NameWithImageField from "src/components/fields/NameWithImageField";
import ProfileField from "src/components/fields/ProfileField";
import TaskNegotiationStateField from "src/components/fields/TaskNegotiationStateField";
import TaskNegotiationTypeField from "src/components/fields/TaskNegotiationTypeField";
import DateRangeInput from "src/components/inputs/DateRangeInput";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import RelatedList from "src/components/list/RelatedList";
import Countries from "src/data/countries";
import { TaskNegotiationStateList, TaskNegotiationTypeList, TaskStatusList } from "src/data/taskStatus";
import { getCategoryTranslation } from "src/utils/translations";

const TaskNegotiationFilter = (props) => (
  <Filter {...props}>
    <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Created"  alwaysOn={true} />
    <TextInput source={'id'} label={'ID'} alwaysOn={true} variant={'outlined'}/>
    <SelectInput source="type" label={'Type'} choices={TaskNegotiationTypeList} alwaysOn={true} allowEmpty={true} variant={'outlined'}/>
    <SelectInput source="state" label={'State'} choices={TaskNegotiationStateList} alwaysOn={true} allowEmpty={true} variant={'outlined'}/>

    <ReferenceInput label="Profile" source="profileId" reference="profile" variant={'outlined'}
                    filter={{ role: 'master' }}
                    filterToQuery={searchText => ({ ...(searchText ? { 'firstName||$contL&lastName||$contL': searchText } : {}) })}
                    sort={{ field: 'id', order: 'DESC' }}>
      <AutocompleteInput optionText={record => `#${record.id} ${record.firstName} ${record.lastName} (${record.role})`} />
    </ReferenceInput>

    <ReferenceInput label="Task" source="taskId" reference="task" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'id||$eq': searchText } : {}) })}
                    sort={{ field: 'id', order: 'ASC' }}>
      <AutocompleteInput optionText={record => `#${record.id} ${record.title}`} />
    </ReferenceInput>
  </Filter>
)

export const TaskNegotiationList = (props) => (
  <List
    {...props}
    title="Offers list"
    filters={<TaskNegotiationFilter/>}
    actions={<ListActions showCreate={false}/>}
    sort={{ field: 'id', order: 'ASC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Offers not found'}/>}
  >
    <Datagrid rowClick={'show'}>
      <TextField source="id" label={'ID'}/>
      <TaskNegotiationTypeField source="type" label={'Type'}/>
      <TaskNegotiationStateField source="state" label={'State'}/>
      <TextField source="ratePerHour" label={'Rate'}/>
      <TextField source="maxWeekHours" label={'Max WH'}/>
      <TextField source="budget" label={'Budget'}/>
      <TextField source="estimate" label={'Estimate'}/>
      <DateField source="createdAt" showTime={true} label={'Created'}/>
      <ReferenceField label="Master" source="profileId" reference="profile" link={'show'}>
        <NameWithImageField source="photo" />
      </ReferenceField>
      <ReferenceField label="Task" source="taskId" reference="task" link={'show'}>
        <TextField source="title"/>
      </ReferenceField>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Offer: #{record.id}
    </span>
  )
}
export const TaskNegotiationShow = (props) => (
    <Show {...props} title={<TitleEdit/>}>
      <TabbedShowLayout>
        <Tab label="Offers" path="">

        </Tab>
      </TabbedShowLayout>
    </Show>
)

export const TaskNegotiationEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <ReferenceInput label="Task" source="taskId" reference="task" variant={'outlined'} fullWidth={true} disabled={true}
                      filterToQuery={searchText => ({ ...(searchText ? { 'id||$eq': searchText} : {}) })}
                      sort={{ field: 'id', order: 'DESC' }}>
        <AutocompleteInput optionText={record => `#${record.id} ${record.title}`} />
      </ReferenceInput>
      <ReferenceInput label="Master" source="profileId" reference="profile" variant={'outlined'} fullWidth={true} disabled={true}
                      filterToQuery={searchText => ({ ...(searchText ? { 'id||$eq': searchText} : {}) })}
                      sort={{ field: 'id', order: 'DESC' }}>
        <AutocompleteInput optionText={record => `#${record.id} ${record.firstName} ${record.lastName} (${record.role})`} />
      </ReferenceInput>
      <TextInput source="message" multiline={true} label={'Description'} variant={'outlined'} fullWidth={true}  validate={required()}/>
      <TextInput source="estimate"  label={'Estimate'} variant={'outlined'} fullWidth={true} />

      <RadioButtonGroupInput source="priceType" choices={[
        { id: 'fixed', name: 'Fixed price' },
        { id: 'rate', name: 'Rate per hour' },
      ]} />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.priceType === 'fixed' && (
            <TextInput source="budget" label={'Budget'} variant={'outlined'} fullWidth={true} />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.priceType === 'rate' && (
            <TextInput source="ratePerHour" label={'Rate per hour'} variant={'outlined'} fullWidth={true} />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)
