import React from 'react'
import PropTypes from 'prop-types'
import sanitizeRestProps from "src/components/sanitizeRestProps";
import { getCategoryTranslation } from "src/utils/translations";
import { Typography } from '@material-ui/core'

const TaskCategoryField = ({ source, record = {}, ...rest }) => {
 return record[source] ? <Typography variant={'body2'}                 {...sanitizeRestProps(rest)}>{getCategoryTranslation(record['category'])?.name || ''} &#8594; {getCategoryTranslation(record['subCategory'])?.name || ''}</Typography> : null
}
TaskCategoryField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool,
}
TaskCategoryField.defaultProps = {
  sourceName: 'name',
  addLabel: true,
};

export default TaskCategoryField
