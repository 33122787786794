import { format } from "date-fns";
import React from 'react'
import PropTypes from "prop-types";
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput, ReferenceField,
} from 'react-admin'
import IdShowButtonField from "src/components/fields/IdShowButtonField";
import { TimeInput } from "src/components/inputs/DateInput";
import { timeToDate } from "src/utils/dateUtil";
import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import PushNameField from "src/resources/Push/components/PushNameField";

const PushFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name||$startsL&title||$startsL&body||$startsL" label={'Поиск'} alwaysOn={true}
               resettable={true}      variant={'outlined'} fullWidth={true}/>


    <SelectInput
      source="status"
      label={'Статус'}
      alwaysOn={true}
      variant={'outlined'} fullWidth={true}
      choices={[
        { id: 'pending', name: 'Отправляется' },
        { id: 'completed', name: 'Отправлено' },
      ]}
    />
    <SelectInput
      source="type"
      label={'Тип'}
      alwaysOn={true}
      variant={'outlined'} fullWidth={true}
      choices={[
          { id: 'all', name: 'Все' },
          { id: 'client', name: 'Клиенты' },
          { id: 'master', name: 'Мастера' },
          { id: 'volunteer', name: 'Волонтеры' },
          { id: 'masterAndVolunteer', name: 'Мастера и волонтеры' },
      ]}
    />

  </Filter>
)

export const PushList = (props) => (
  <List
    {...props}
    title="Push уведомления"
    filters={<PushFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Нет push уведомлений'} description={'Вы можете отправить push уведомление'}
                      buttonText={'Создать push уведомление'}/>}
  >

    <Datagrid rowClick={'edit'}>
      <TextField source="id" label={'ID'}/>
      {(props.permissions === 'super_admin' || props.permissions === 'admin') &&  <ReferenceField label="Аккаунт" source="accountId" reference="account">
        <TextField source="name" />
      </ReferenceField>}
      <PushNameField source="name" label={'Push'}/>
      <TextField source="typeName" label={'Тип'}/>
      <TextField source="statusName" label={'Статус'}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Push уведомление: {record.name || record.title}
    </span>
  )
}


const Form = ({ permissions, record, ...rest }) => {
  const source = rest.source || '';
  return (<>


    <SelectInput
      validate={required()}
      source="type"
      label={'Кому отправить'}
      variant={'outlined'} fullWidth={true}
      choices={[
          { id: 'all', name: 'Все' },
          { id: 'client', name: 'Клиенты' },
          { id: 'master', name: 'Мастера' },
          { id: 'volunteer', name: 'Волонтеры' },
          { id: 'masterAndVolunteer', name: 'Мастера и волонтеры' },
      ]}
    />
    <SelectInput
      source="setStatus"
      initialValue={record ? record.status : null}
      label={'Статус'}
      variant={'outlined'} fullWidth={true}
      choices={[
        { id: 'created', name: 'Черновик' },
        { id: 'toSent', name: 'Отправить' },
      ]}
    />

    <TextInput source="title" label={'Название уведомления (Необязательно)'}
               helperText={'Заголовок уведомления отображается для конечных пользователей.'} variant={'outlined'}
               fullWidth={true} />
    <TextInput source="body" label={'Текст уведомления'} helperText={'Отображется под название уведомления'}
               variant={'outlined'} fullWidth={true} validate={required()}/>
    <TextInput source="name" label={'Название уведомления (необязательно)'}
               helperText={'Название используется только для идентификации этого уведомления в нашем сервисе. Пользователи его не увидят.'}
               variant={'outlined'} fullWidth={true}/>
    <TextInput source="icon" label={'Изображение в уведомлении (Необязательно)'}
               helperText={'Вставьте общедоступную ссылку на изображение.'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="color" label={'Цвет иконки уведомления (Необязательно)'} helperText={'Только для Android'} variant={'outlined'}
               fullWidth={true}/>

    <SelectInput
      source="sound"
      initialValue={'default'}
      label={'Звук'}
      variant={'outlined'} fullWidth={true}
      choices={[
        { id: '', name: 'Отключен' },
        { id: 'default', name: 'Включен' },
      ]}
    />
    <TextInput source="tag" label={'Тэг (название группы уведмоления) (Необязательно)'}
               helperText={'Если есть активные уведомления с таким тэгом то они будут скрыты в центре уведомления. Только для Android'}
               variant={'outlined'} fullWidth={true}/>
    <SelectInput
      source="priority"
      label={'Приоритет'}
      initialValue={'high'}
      validate={required()}
      variant={'outlined'} fullWidth={true}
      helperText={'By default, notification messages are sent with high priority, and data messages are sent with normal priority. Normal priority optimizes the client app\'s battery consumption and should be used unless immediate delivery is required. For messages with normal priority, the app may receive the message with unspecified delay.\n' +
      '\n' +
      'When a message is sent with high priority, it is sent immediately, and the app can wake a sleeping device and open a network connection to your server.'}
      choices={[
        { id: 'high', name: 'Высокий' },
        { id: 'normal', name: 'Обычный' },
      ]}
    />

    <BooleanInput source="isScheduledSent" label={'Отправлять периодически'} helperText={''} variant={'outlined'} fullWidth={true}/>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.isScheduledSent && (
        <TimeInput
          type={'time'}
          source="scheduleSentAt"
          label="Каждый день в"
          options={{ format: 'HH:mm', ampm: false }}
          format={(val) =>  timeToDate(val)}
          ampm={false}
          parse={(val) => format(new Date(val), 'HH:mm:00xx')}
          fullWidth={true}
          variant={'outlined'}
        />
      )}
    </FormDataConsumer>


  </>)
}
Form.propTypes = {
  record: PropTypes.object,
  permissions: PropTypes.string,
}

Form.defaultProps = {}

export const PushEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form {...props} />
    </SimpleForm>
  </Edit>
)

export const PushCreate = (props) => (
  <Create title="Создать Push уведомление" {...props}>
    <SimpleForm redirect={'list'}>
      <Form {...props} />
    </SimpleForm>
  </Create>
)
