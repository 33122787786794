import React from 'react'
import {
    required,
    List,
    Datagrid,
    Filter,
    SelectInput,
    ReferenceManyField,
    ReferenceField,
    Tab,
    TabbedShowLayout,
    Show,
    Edit,
    Create,
    DateField,
    SimpleForm,
    TextField,
    TextInput,
    FormDataConsumer,
    ReferenceInput,
    BooleanInput,
    ImageField,
    Pagination,
    AutocompleteInput, EditButton,
} from 'react-admin'
import AvatarField from "src/components/fields/AvatarField";
import BooleanField from "src/components/fields/BooleanField";
import FeedBackRateField from "src/components/fields/FeedBackRateField";
import NameWithImageField from "src/components/fields/NameWithImageField";
import ProfileField from "src/components/fields/ProfileField";
import TaskCategoryField from "src/components/fields/TaskCategoryField";
import TaskNegotiationStateField from "src/components/fields/TaskNegotiationStateField";
import TaskNegotiationTypeField from "src/components/fields/TaskNegotiationTypeField";
import TaskStatusField from "src/components/fields/TaskStatusField";
import DateRangeInput from "src/components/inputs/DateRangeInput";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import RelatedList from "src/components/list/RelatedList";
import Countries from "src/data/countries";
import OrderButtons from "../../components/list/OrderButtons";
import S3ImageInput from "../../components/inputs/S3FileUpload/S3ImageUpload";
import S3ImageField from "../../components/fields/S3ImageField";
import {MarksList} from "../../data/marks";

const BannerFilter = (props) => (
    <Filter {...props}>
        <TextInput source="title||$contL&place||$contL" label={'Search'}
                   alwaysOn={true} resettable={true} variant={'outlined'}/>

    </Filter>
)

export const BannerList = (props) => (
    <List
        {...props}
        title="Profile list"
        filters={<BannerFilter/>}
        actions={<ListActions showCreate={false}/>}
        sort={{ field: 'id', order: 'ASC' }}
        bulkActionButtons={false}
        empty={<EmptyList title={'Banners not found'} description={'You can add a banner'}/>}
    >
        <Datagrid rowClick={'show'}>
            <TextField source="id" label={'ID'}/>
            <TextField source="title" label={'Title'}/>
            <TextField source="place" label={'Place'}/>
            <DateField source="createdAt" label={'Created'} showTime={true}/>
            <EditButton />
            <OrderButtons source="sort" />
        </Datagrid>
    </List>
)


const TitleEdit = ({ record }: any) => {
    return (
        <span>
      Banner: {record.title}
    </span>
    )
}



const Form = ({...rest}) => {

    return (<>
        <TextInput source="title" label={'Title'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="place" label={'Place'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="description" label={'Description'} variant={'outlined'} fullWidth={true} />
        <S3ImageInput
            source="picture"
            label={'Картинка'}
            uploadOptions={{
                multiple: false
            }}
        >
            <S3ImageField source="src" title="title"/>
        </S3ImageInput>

    </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const BannerEdit = (props) => (
    <Edit {...props} title={<TitleEdit/>} undoable={false}>
        <SimpleForm redirect={'list'}>
            <Form {...props}/>
        </SimpleForm>
    </Edit>
)

export const BannerCreate = (props) => {
    return(
        <Create title="Создать Баннер" {...props}>
            <SimpleForm redirect={'list'} >
                <Form {...props}/>
            </SimpleForm>
        </Create>
    )}

