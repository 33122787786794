import React from 'react'
import {
    required,
    List,
    Datagrid,
    Filter,
    SelectInput,
    ReferenceManyField,
    ReferenceField,
    Tab,
    TabbedShowLayout,
    Show,
    Edit,
    Create,
    DateField,
    SimpleForm,
    TextField,
    TextInput,
    FormDataConsumer,
    ReferenceInput,
    BooleanInput,
    ImageField,
    Pagination,
    AutocompleteInput, EditButton,
} from 'react-admin'
import AvatarField from "src/components/fields/AvatarField";
import BooleanField from "src/components/fields/BooleanField";
import FeedBackRateField from "src/components/fields/FeedBackRateField";
import NameWithImageField from "src/components/fields/NameWithImageField";
import ProfileField from "src/components/fields/ProfileField";
import TaskCategoryField from "src/components/fields/TaskCategoryField";
import TaskNegotiationStateField from "src/components/fields/TaskNegotiationStateField";
import TaskNegotiationTypeField from "src/components/fields/TaskNegotiationTypeField";
import TaskStatusField from "src/components/fields/TaskStatusField";
import DateRangeInput from "src/components/inputs/DateRangeInput";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import RelatedList from "src/components/list/RelatedList";
import Countries from "src/data/countries";
import OrderButtons from "../../components/list/OrderButtons";
import S3ImageInput from "../../components/inputs/S3FileUpload/S3ImageUpload";
import S3ImageField from "../../components/fields/S3ImageField";
import {MarksList} from "../../data/marks";
import ChatMessageField from "../../components/fields/ChatMessageField";
import ProfileWithImageField from "../../components/fields/ProfileWithImageField";

const ChatFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Task" source="taskId" reference="task" variant={'outlined'} alwaysOn={true} allowEmpty={true} resettable={true}
                        filterToQuery={searchText => ({ ...(searchText ? { 'id||$eq': searchText } : {}) })}
                        sort={{ field: 'id', order: 'ASC' }}>
            <AutocompleteInput optionText={record => `#${record.id} ${record.title}`} />
        </ReferenceInput>
        <ReferenceInput label="Profile" source="profileId" reference="profile" variant={'outlined'} alwaysOn={true} allowEmpty={false} resettable={true}
                        filterToQuery={searchText => ({ ...(searchText ? { 'firstName||$contL&lastName||$contL': searchText } : {}) })}
                        sort={{ field: 'lastName', order: 'ASC' }}>
            <AutocompleteInput optionText={record => `${record.firstName} ${record.lastName} (${record.role})`} />
        </ReferenceInput>


    </Filter>
)

export const ChatList = (props) => (
    <List
        {...props}
        title="Chat list"
        filters={<ChatFilter/>}
        actions={<ListActions showCreate={false}/>}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        empty={<EmptyList title={'Chats not found'} description={'You can add a chat'}/>}
    >
        <Datagrid rowClick={'show'}>
            <TextField source="id" label={'ID'}/>
            <ProfileWithImageField source="profile" label={'User 1'}/>
            <ProfileWithImageField source="participant" label={'User 2'}/>
            <TextField source="task.title" label={'Task'}/>
            <DateField source="lastMessageAt" label={'Last Message'} showTime={true}/>
            <DateField source="createdAt" label={'Created'} showTime={true}/>
        </Datagrid>
    </List>
)


const ChatEdit = ({ record }: any) => {
    return (
        <span>
      Chat #{record.id} between {record.profile.firstName} {record.profile.lastName} and {record.participant?.firstName} {record.participant?.lastName}
    </span>
    )
}



export const ChatShow = (props) => (
    <Show {...props} title={<ChatEdit/>}>
        <TabbedShowLayout>
            <Tab label="Messages" path="">
                <ReferenceManyField reference="chat-message" target="chatId" addLabel={false}
                                    sort={{ field: 'createdAt', order: 'DESC' }} pagination={<Pagination/>} perPage={50}>
                    <RelatedList
                        emptyTitle={'Messages list empty'}>
                        <TextField source="id" label={'ID'}/>
                        <ProfileWithImageField source="profile" label={'Author'}/>

                        <ChatMessageField source="message" label={'Message'}/>
                        <DateField source="createdAt" showTime={true} label={'Created'}/>
                    </RelatedList>

                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)
