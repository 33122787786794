import React from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  DateField,
  Show, TabbedShowLayout, Tab, ReferenceManyField,Pagination,
  RadioButtonGroupInput,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput,
} from 'react-admin'
import BooleanField from "src/components/fields/BooleanField";
import FeedBackRateField from "src/components/fields/FeedBackRateField";
import NameWithImageField from "src/components/fields/NameWithImageField";
import ProfileField from "src/components/fields/ProfileField";
import S3ImageField from "src/components/fields/S3ImageField";
import TaskCategoryField from "src/components/fields/TaskCategoryField";

import DateRangeInput from "src/components/inputs/DateRangeInput";
import S3ImageInput from "src/components/inputs/S3FileUpload/S3ImageUpload";
import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import RelatedList from "src/components/list/RelatedList";
import Countries from "src/data/countries";
import { MarksList } from "src/data/marks";
import { getCategoryTranslation } from "src/utils/translations";

const SkillFilter = (props) => (
  <Filter {...props}>
    <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Date Range"  alwaysOn={true} />
    <TextInput source={'id'} label={'ID'} alwaysOn={true} variant={'outlined'}/>
    <ReferenceInput label="Profile" source="fromProfileId" reference="profile" variant={'outlined'} alwaysOn={true}
                    filter={{ role: 'client' }}
                    filterToQuery={searchText => ({ ...(searchText ? { 'firstName||$contL&lastName||$contL': searchText } : {}) })}
                    sort={{ field: 'lastName', order: 'DESC' }}>
      <AutocompleteInput optionText={record => `${record.firstName} ${record.lastName}`} />
    </ReferenceInput>
      <ReferenceInput label="Category" source="categoryId" reference="service-category" allowEmpty={true} variant={'outlined'}
                      filterToQuery={searchText => ({ ...(searchText ? { 'translations.name||$contL': searchText } : {}) })}
                      filter={{ isMain: true  }}
                      sort={{ field: 'translations.name', order: 'DESC' }}>
          <SelectInput optionText={record => getCategoryTranslation(record)?.name} />
      </ReferenceInput>

  </Filter>
)

export const SkillList = (props) => (
  <List
    {...props}
    title="Skill list"
    filters={<SkillFilter/>}
    actions={<ListActions showCreate={false}/>}
    sort={{ field: 'id', order: 'ASC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Skills not found'}/>}
  >
    <Datagrid rowClick={'edit'}>
        <TextField source="id" label={'ID'}/>
        <TaskCategoryField source="category" label={'Category'}/>
        <TextField source="description" label={'Description'}/>
        <TextField source="priceType" label={'Price type'}/>
        <TextField source="ratePerHour" label={'Rate'}/>
        <TextField source="price" label={'Price'}/>
        <DateField source="createdAt" showTime={true} label={'Created'}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Skill: #{record.id}
    </span>
  )
}
export const SkillShow = (props) => (
    <Show {...props} title={<TitleEdit/>}>
      <TabbedShowLayout>
        <Tab label="Offers" path="">

        </Tab>
      </TabbedShowLayout>
    </Show>
)



const Form = ({...rest}) => {

  return (<>
    <ReferenceInput label="Profile" source="profileId" reference="profile" variant={'outlined'} fullWidth={true} validate={required()}
                    filterToQuery={searchText => ({ ...(searchText ? { 'id||$eq': searchText} : {}) })}
                    sort={{ field: 'id', order: 'DESC' }}>
      <AutocompleteInput optionText={record => `#${record.id} ${record.firstName} ${record.lastName} (${record.role})`} />
    </ReferenceInput>
      <ReferenceInput label="Category" source="categoryId" reference="service-category" fullWidth={true} variant={'outlined'} validate={required()}
                      filterToQuery={searchText => ({ ...(searchText ? { 'translations.name||$contL': searchText } : {}) })}
                      filter={{ isMain: true  }}
                      sort={{ field: 'translations.name', order: 'DESC' }}>
          <AutocompleteInput optionText={record => getCategoryTranslation(record)?.name} />
      </ReferenceInput>
      <FormDataConsumer>
          {({ formData, ...rest }) =>  (
              <ReferenceInput label="Sub Category" source="subCategoryId" reference="service-category" variant={'outlined'} fullWidth={true} validate={required()}
                              filterToQuery={searchText => ({ ...(searchText ? { 'translations.name||$contL': searchText } : {}) })}
                              filter={{parentId: formData.categoryId}}
                              sort={{ field: 'translations.name', order: 'DESC' }}>
                  <AutocompleteInput optionText={record => getCategoryTranslation(record)?.name} />
              </ReferenceInput> )}
      </FormDataConsumer>

      <S3ImageInput
          source="photos"
          label={'Photos'}
          uploadOptions={{
              multiple: true
          }}
      >
          <S3ImageField source="src" title="title"/>
      </S3ImageInput>
      <RadioButtonGroupInput source="priceType" choices={[
          { id: 'fixed', name: 'Fixed price' },
          { id: 'rate', name: 'Rate per hour' },
      ]} />
      <FormDataConsumer>
          {({ formData, ...rest }) => formData.priceType === 'fixed' && (
              <TextInput source="price" label={'Price'} variant={'outlined'} fullWidth={true} />
          )}
      </FormDataConsumer>
      <FormDataConsumer>
          {({ formData, ...rest }) => formData.priceType === 'rate' && (
              <TextInput source="ratePerHour" label={'Rate per hour'} variant={'outlined'} fullWidth={true} />
          )}
      </FormDataConsumer>
    <TextInput source="description" multiline={true} label={'Description'} variant={'outlined'} fullWidth={true}/>

  </>)
}
Form.propTypes = {

}

Form.defaultProps = {

}

export const SkillEdit = (props) => {
    const { location } = props
    const redirect = location.state && location.state.previous ? location.state.previous : 'list'

    return (
    <Edit {...props} title={<TitleEdit/>} undoable={false}>
        <SimpleForm redirect={redirect}>
            <Form {...props}/>
        </SimpleForm>
    </Edit>)
}

export const SkillCreate = (props) => {
  return(
      <Create title="Создать отзыв" {...props}>
        <SimpleForm redirect={'list'} >
          <Form {...props}/>
        </SimpleForm>
      </Create>
  )}

