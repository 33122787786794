import React from 'react'
import PropTypes from 'prop-types'

const PushNameField = ({ record = {},
                       }: any) => {
  return (<>
   <div><b>{record.name || record.title}</b></div>
    {record.body && <div>{record.body}</div>}
  </>)
}

PushNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
}

PushNameField.defaultProps = {

};
export default PushNameField
