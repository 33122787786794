import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import Avatar from "src/components/Avatar";
import { makeStyles } from '@material-ui/core/styles'
import {ITaskNegotiationType} from "../types";
const useStyles = makeStyles(
    {
        root: {
            display: 'flex',
            alignItems: 'center',
            "&:hover": {
                "& $text": {
                    textDecoration: 'underline'
                }
            }
        },
        text: {
            marginLeft: '0.5rem',

        },

    },
    { name: 'RaImageField' },
)
const NameWithImageField = ({
                                source, sourceName, sourceSecondName, sourceImage, firstLetter,  record = {},
                            }: any) => {
    const classes = useStyles()
    const model = source && record[source] ? record[source] : record;
    let message = '';
    const taskNegotiation = record['taskNegotiation'];
    const authorName = `${record['firstName']} ${record['lastName']} (${record['role'] === 'client' ? 'Client' : 'Master'})`
    switch (record.type){
        case 'text':
            message = record['message'];
            break;
        case 'file':
            break;
        case 'image':

            break;
        case 'task_negotiation':
            switch (taskNegotiation.type){
                case ITaskNegotiationType.MarkAsDone:
                    message = `Mark task as done`
                    break;
                case ITaskNegotiationType.TaskOffer:
                    message = `Send task offer`
                    break;
                case ITaskNegotiationType.TaskNegotiation:
                    break;
                case ITaskNegotiationType.ResponseToTask:
                    message = `Response to task (Negotiation started)`
                    break;
                case ITaskNegotiationType.TaskCompleted:
                    message = `Task completed`
                    break;
                case ITaskNegotiationType.TaskCanceled:
                    message = `Cancel task`
                    break;
                case ITaskNegotiationType.MasterAssigned:
                    message = `Assign master`
                    break;
            }
            break;
    }
    console.log("Login", model, sourceName);
    return (<div className={classes.root}>
        <div className={classes.text}> {message}</div>
       <div className={classes.text}> {message}</div>
    </div>)
}

NameWithImageField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    firstLetter: PropTypes.bool,
    source: PropTypes.string,
    sourceName: PropTypes.string.isRequired,
    sourceImage: PropTypes.string.isRequired,
}

NameWithImageField.defaultProps = {
    firstLetter: true,
    sourceName: 'firstName',
    sourceSecondName: 'lastName',
    sourceImage: 'photo',

};
export default NameWithImageField
