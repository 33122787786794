import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

const ProfileField = ({ source, sourceName, record = {} }) => {
 return record[source] ? <Typography variant={'body2'}>{record['firstName']} {record['lastName']}</Typography> : null
}
ProfileField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  sourceName: PropTypes.string,
}
ProfileField.defaultProps = {
  sourceName: 'name'
};

export default ProfileField
