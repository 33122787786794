import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import Avatar from "src/components/Avatar";
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(
    {
        root: {
            display: 'flex',
            alignItems: 'center',
            "&:hover": {
                "& $text": {
                    textDecoration: 'underline'
                }
            }
        },
        text: {
            marginLeft: '0.5rem',

        },

    },
    { name: 'RaImageField' },
)
const NameWithImageField = ({
                                source, sourceName, sourceSecondName, sourceImage, firstLetter,  record = {},
                            }: any) => {
    const classes = useStyles()
    const model = source && record[source] ? record[source] : record;
    console.log("Login", model, sourceName);
    return (<div className={classes.root}>
        { model || firstLetter ? <Avatar image={model[sourceImage] || (model?.icon?.icon)} name={model[sourceName]}/> : null} {model[sourceName] ? <div className={classes.text}> {model[sourceName]} {model[sourceSecondName] || ''}</div> : null}
    </div>)
}

NameWithImageField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    firstLetter: PropTypes.bool,
    source: PropTypes.string,
    sourceName: PropTypes.string.isRequired,
    sourceImage: PropTypes.string.isRequired,
}

NameWithImageField.defaultProps = {
    firstLetter: true,
    sourceName: 'firstName',
    sourceSecondName: 'lastName',
    sourceImage: 'photo',

};
export default NameWithImageField
