import React from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput,
} from 'react-admin'

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
const UserFiler = (props) => (
  <Filter {...props}>
    <TextInput source="phone||$contL&email||$contL&firstName||$contL&lastName||$contL" label={'Search'} alwaysOn={true} resettable={true} variant={'outlined'}/>
    <TextInput source="id" label={'ID'} alwaysOn={true} variant={'outlined'}/>

  </Filter>
)

export const UserList = (props) => (
  <List
    {...props}
    title="User list"
    filters={<UserFiler/>}
    actions={<ListActions/>}
    sort={{ field: 'id', order: 'ASC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Users not found'} description={'You can add a user'} buttonText={'Create user'}/>}
  >
    <Datagrid rowClick={'edit'}>
      <TextField source="id" label={'ID'}/>
        <TextField source="firstName" label={'First name'}/>
        <TextField source="lastName" label={'Last name'}/>
        <TextField source="email" label={'Email'}/>
        <TextField source="phone" label={'Phone'}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      User: {record.name}
    </span>
  )
}

export const UserEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <TextInput source="email" label={'Email'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <TextInput source="phone" label={'Phone'} variant={'outlined'} fullWidth={true}  validate={required()}/>
      <TextInput source="firstName" label={'First name'} variant={'outlined'} fullWidth={true}  validate={required()}/>
      <TextInput source="lastName" label={'Last name'} variant={'outlined'} fullWidth={true}  validate={required()}/>
    </SimpleForm>
  </Edit>
)
