import { makeStyles } from "@material-ui/core/styles";
import MuiTextField from "@material-ui/core/TextField/TextField";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {format as dateFormat, parse as parseFormat} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslate } from "react-admin";
import sanitizeRestProps from "src/components/sanitizeRestProps";
import { InputHelperText, Labeled } from 'ra-ui-materialui'

const useStyles = makeStyles(
  theme => ({
   wrapper: {
     position: 'absolute'
   },
   input: {

   }
  }),
  { },
)
const DateRangeInput = (props) => {
  const {...fieldProps } = props
  const classes = useStyles(props)
  const {
    options,
    label,
    source,
    sourceStart,
    sourceEnd,
    format,
    parse,
    resource,
    className,
    providerOptions,
    validate,
    ...rest
  } = fieldProps;
  const [open, setOpen] = React.useState(false);
  const translate = useTranslate()
  const toggle = () => setOpen(!open);

  const {
    id,
    input: { onChange:  onChangeInput, value, ...inputProps },
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,

    parse,
    resource,
    source: sourceStart,
    type: 'text',
    validate,
    ...rest,
  });
  const {

    input: { onChange:  onChangeInput1, value: valueEnd },

  } = useInput({
    format,

    parse,
    resource,
    source: sourceEnd,
    type: 'text',
    validate,
    ...rest,
  });

  const handleChange = useCallback((value: DateRange) => {
    onChangeInput(`${dateFormat(value.startDate, 'yyyy-MM-dd')}`)
    onChangeInput1(`${dateFormat(value.endDate, 'yyyy-MM-dd')}`)
  //  onChangeInpu({lat: e.latLng.lat(), lng: e.latLng.lng()})
    //Date.parse(value) ? input.onChange(value.toISOString()) : input.onChange(null);
  }, []);

  return (
      <Labeled
        label={<FieldTitle
          label={label}
        />}
        {...sanitizeRestProps(rest)}
      >
        <div>
        <div>
          <div onClick={toggle}>
          <MuiTextField
            disabled={true}
            value={`${value ? dateFormat(parseFormat(value, 'yyyy-MM-dd', new Date()), 'dd.MM.yy') : ''}${value && valueEnd ? ' - ': ''}${valueEnd ? dateFormat(parseFormat(valueEnd, 'yyyy-MM-dd', new Date()), 'dd.MM.yy') : ''}`}
          />
          </div>


        </div>
        <div className={classes.wrapper}>
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range) => handleChange(range)}
          />
        </div>
      </div>
      </Labeled>
  )
}

DateRangeInput.propTypes = {

  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  sourceStart: PropTypes.string,
  sourceEnd: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  alwaysOn: PropTypes.bool,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

DateRangeInput.defaultProps = {
  meta: { touched: false, error: false },
  options: {},
  resource: '',
  source: '',
  labelTime: '',
  className: '',
  alwaysOn: false,
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};

export default DateRangeInput
