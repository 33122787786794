import React from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  DateField,
  Show, TabbedShowLayout, Tab, ReferenceManyField,Pagination,
  RadioButtonGroupInput,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput,
} from 'react-admin'
import BooleanField from "src/components/fields/BooleanField";
import FeedBackRateField from "src/components/fields/FeedBackRateField";
import NameWithImageField from "src/components/fields/NameWithImageField";
import ProfileField from "src/components/fields/ProfileField";
import TaskCategoryField from "src/components/fields/TaskCategoryField";
import TaskNegotiationStateField from "src/components/fields/TaskNegotiationStateField";
import TaskNegotiationTypeField from "src/components/fields/TaskNegotiationTypeField";
import TaskStatusField from "src/components/fields/TaskStatusField";
import DateRangeInput from "src/components/inputs/DateRangeInput";
import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import RelatedList from "src/components/list/RelatedList";
import Countries from "src/data/countries";
import { TaskStatusList } from "src/data/taskStatus";
import { getCategoryTranslation } from "src/utils/translations";

const TaskFilter = (props) => (
  <Filter {...props}>
    <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Created"  alwaysOn={true} />

    <TextInput source={'id'} label={'ID'} alwaysOn={true} variant={'outlined'}/>

    <TextInput source="name||$contL&description||$contL" label={'Search'} alwaysOn={true} resettable={true} variant={'outlined'}/>
    <SelectInput source="status" label={'Status'} choices={TaskStatusList}  allowEmpty={true} variant={'outlined'}/>
    <ReferenceInput label="Category" source="categoryId" reference="service-category" allowEmpty={true} variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'translations.name||$contL': searchText } : {}) })}
                    filter={{ isMain: true  }}
                    sort={{ field: 'translations.name', order: 'DESC' }}>
      <SelectInput optionText={record => getCategoryTranslation(record)?.name} />
    </ReferenceInput>
    <ReferenceInput label="Profile" source="profileId" reference="profile" variant={'outlined'}
                    filter={{ role: 'client' }}
                    filterToQuery={searchText => ({ ...(searchText ? { 'firstName||$contL&lastName||$contL': searchText } : {}) })}
                    sort={{ field: 'lastName', order: 'DESC' }}>
      <AutocompleteInput optionText={record => `${record.firstName} ${record.lastName}`} />
    </ReferenceInput>
    <ReferenceInput label="City" source="geonameid" reference="location" variant={'outlined'} fullWidth={true}   filter={{ country: 'CA', fclass: 'P', fcode: 'PPLA' }}
                    filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText } : {}) })}
                    sort={{ field: 'population', order: 'DESC' }}>
      <AutocompleteInput optionText="name" optionValue="geonameid" />
    </ReferenceInput>
    <AutocompleteInput label="Country" source={'geoname.country'} choices={Countries} variant={'outlined'} fullWidth={true}  />

  </Filter>
)

export const TaskList = (props) => (
  <List
    {...props}
    title="Task list"
    filters={<TaskFilter/>}
    actions={<ListActions showCreate={false}/>}
    sort={{ field: 'id', order: 'ASC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Tasks not found'}/>}
  >
    <Datagrid rowClick={'show'}>
      <TextField source="id" label={'ID'}/>
      <TextField source="title" label={'Title'}/>
      <TaskCategoryField source="category" label={'Category'}/>

      <TaskStatusField source="status" label={'Status'}/>
      <TextField source="geoname.name" label={'Location'}/>
      <TextField source="priceType" label={'Price Type'}/>
      <TextField source="budget" label={'Budget'}/>
      <TextField source="ratePerHour" label={'Rate'}/>
      <TextField source="masterRole" label={'Master Role'}/>
      <DateField source="createdAt" showTime={true} label={'Created'}/>
      <ReferenceField label="Author" source="profileId" reference="profile" link={'show'}>
        <ProfileField source="firstName" />
      </ReferenceField>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Task: {record.title} #{record.id}
    </span>
  )
}
export const TaskShow = (props) => (
    <Show {...props} title={<TitleEdit/>}>
      <TabbedShowLayout>
        <Tab label="Info" path="">
          <TextField source="id" label={'ID'}/>
          <TextField source="title" label={'Title'}/>
          <TaskCategoryField source="category" label={'Category'}/>
          <TaskStatusField source="status" label={'Status'}/>
          <TextField source="geoname.name" label={'Location'}/>
          <TextField source="priceType" label={'Price Type'}/>
          <TextField source="budget" label={'Budget'}/>
          <TextField source="ratePerHour" label={'Rate'}/>
          <TextField source="masterRole" label={'Master Role'}/>
          <DateField source="createdAt" showTime={true} label={'Created'}/>
          <ReferenceField label="Author" source="profileId" reference="profile" link={'show'}>
            <NameWithImageField source="photo" />
          </ReferenceField>

          <TextField source="description" label={'description'}/>
        </Tab>
        <Tab label="Offers" path="offers">
          <ReferenceManyField reference="task-negotiation" target="taskId" addLabel={false}   sort={{ field: 'id', order: 'DESC' }} pagination={<Pagination/>} perPage={50}>
            <RelatedList
                emptyTitle={'Task offers empty'}>
              <TextField source="id" label={'ID'}/>
              <TaskNegotiationTypeField source="type" label={'Type'}/>
              <TaskNegotiationStateField source="state" label={'State'}/>
              <TextField source="ratePerHour" label={'Rate'}/>
              <TextField source="maxWeekHours" label={'Max WH'}/>
              <TextField source="budget" label={'Budget'}/>
              <TextField source="estimate" label={'Estimate'}/>
              <DateField source="createdAt" showTime={true} label={'Created'}/>
              <ReferenceField label="Master" source="profileId" reference="profile" link={'show'}>
                <NameWithImageField source="photo" />
              </ReferenceField>
            </RelatedList>

          </ReferenceManyField>
        </Tab>
        <Tab label="Feedback" path="feedback">
          <ReferenceManyField reference="feedback" target="taskId" addLabel={false}   sort={{ field: 'id', order: 'DESC' }} pagination={<Pagination/>} perPage={50}>
            <RelatedList
                emptyTitle={'No feedback given'}>
          <TextField source="id" label={'ID'}/>
          <TextField source="target" label={'Target'}/>
          <FeedBackRateField source={'rate'} label={'Rating'}/>
          <TextField source="description" label={'Description'}/>

          <DateField source="createdAt" showTime={true} label={'Created'}/>
          <BooleanField source={'showInFirstPage'} label={'Main page'}/>
          <ReferenceField label="Author" source="fromProfileId" reference="profile" link={'show'}>
            <ProfileField source="firstName" />
          </ReferenceField>
          <ReferenceField label="To" source="toProfileId" reference="profile" link={'show'}>
            <ProfileField source="firstName" />
          </ReferenceField>
          <ReferenceField label="Task" source="taskId" reference="task" link={'show'}>
            <ProfileField source="firstName" />
          </ReferenceField>
            </RelatedList>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
)

export const TaskEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <SelectInput source="status" label={'Status'} choices={TaskStatusList} variant={'outlined'} fullWidth={true}/>

      <TextInput source="title" label={'Title'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <TextInput source="description" multiline={true} label={'Description'} variant={'outlined'} fullWidth={true} />
      <ReferenceInput label="Category" source="categoryId" reference="service-category" fullWidth={true} variant={'outlined'} validate={required()}
                      filterToQuery={searchText => ({ ...(searchText ? { 'translations.name||$contL': searchText } : {}) })}
                      filter={{ isMain: true  }}
                      sort={{ field: 'translations.name', order: 'DESC' }}>
        <AutocompleteInput optionText={record => getCategoryTranslation(record)?.name} />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) =>  (
            <ReferenceInput label="Sub Category" source="subCategoryId" reference="service-category" variant={'outlined'} fullWidth={true} validate={required()}
                            filterToQuery={searchText => ({ ...(searchText ? { 'translations.name||$contL': searchText } : {}) })}
                            filter={{parentId: formData.categoryId}}
                            sort={{ field: 'translations.name', order: 'DESC' }}>
              <AutocompleteInput optionText={record => getCategoryTranslation(record)?.name} />
            </ReferenceInput> )}
      </FormDataConsumer>



      <SelectInput source="masterRole" label={'Master role'} variant={'outlined'} fullWidth={true}  validate={required()} choices={[{id: 'master', name: 'Master'}, {id: 'volunteer', name: 'Volunteer'}]}/>
      <AutocompleteInput label="Country" source={'countryCode'} choices={Countries} variant={'outlined'} fullWidth={true}  />

      <FormDataConsumer>
        {({ formData, ...rest }) =>  (
            <ReferenceInput label="City" source="geonameid" reference="location" variant={'outlined'} fullWidth={true}
                            filter={{ country: formData.countryCode || 'CA', fclass: 'P', fcode: 'PPLA' }}
                            filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText } : {}) })}
                            sort={{ field: 'population', order: 'DESC' }}>
              <AutocompleteInput optionText="name" optionValue="geonameid" />
            </ReferenceInput>
        )}
      </FormDataConsumer>


      <TextInput source="address" label={'Address'} variant={'outlined'} fullWidth={true}  />
      <RadioButtonGroupInput source="priceType" choices={[
        { id: 'fixed', name: 'Fixed price' },
        { id: 'rate', name: 'Rate per hour' },
      ]} />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.priceType === 'fixed' && (
            <TextInput source="budget" label={'Budget'} variant={'outlined'} fullWidth={true} />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.priceType === 'rate' && (
            <TextInput source="ratePerHour" label={'Rate per hour'} variant={'outlined'} fullWidth={true}/>
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)
