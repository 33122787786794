import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import sanitizeRestProps from "src/components/sanitizeRestProps";
const StatusMap = {
  draft: 'Draft',
  published: 'Published',
  negotiation: 'Negotiation',
  paused: 'Paused',
  canceled: 'Canceled',
  in_progress: 'In Progress',
  done: 'Done'
};
const TaskStatusField = ({ source, record = {}, ...rest }) => {
 return record[source] ? <Typography variant={'body2'}   {...sanitizeRestProps(rest)}>{StatusMap[record[source]] || record[source]}</Typography> : null
}
TaskStatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}
TaskStatusField.defaultProps = {
  addLabel: true
};

export default TaskStatusField
