export const TaskStatusList = [
    {id: 'draft', name: 'Draft'},
    {id: 'published', name: 'Published'},
    {id: 'negotiation', name: 'Negotiation'},
    {id: 'paused', name: 'Paused'},
    {id: 'canceled', name: 'Canceled'},
    {id: 'in_progress', name: 'In Progress'},
    {id: 'done', name: 'Done'},
    ];
export const TaskNegotiationTypeList = [
    {id: 'response_to_task', name: 'Response'},
    {id: 'task_offer', name: 'Offer'},
    {id: 'task_negotiation', name: 'Negotiation'},
    {id: 'mark_as_done', name: 'Mark as done'},
    {id: 'task_completed', name: 'Completed'},
]

export const TaskNegotiationTypeMap = {
    response_to_task: 'Response',
    task_offer: 'Offer',
    task_negotiation: 'Negotiation',
    mark_as_done: 'Mark as done',
    task_completed: 'Completed',
}
export const TaskNegotiationStateList = [
    {id: 'init', name: 'Init'},
    {id: 'declined', name: 'Declined'},
    {id: 'accepted', name: 'Accepted'},
]

export const TaskNegotiationStateMap = {
    init: 'Init',
    declined: 'Declined',
    accepted: 'Accepted',
}
