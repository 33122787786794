import React, {useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, Confirm, useNotify
} from "react-admin";

import {Button} from '@material-ui/core'
import {OrganizationStatus} from 'src/types'
interface Props {
  data: any,
  status: OrganizationStatus
}
export const OrderChangeStatusButton = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const record = props.data;
  console.log("StatusProps", props)
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

    const handleSubmit = async () => {
      setLoading(true);
        const res = await dataProvider.update('organization-moderation', {
          id: record.id,
          data: {status: props.status},
          previousData: record
        });

      setLoading(false);
     await refresh();
     setIsOpen(false);
      return true;
    };

  const renderTitle = () => {
    switch (props.status){
      case OrganizationStatus.Rejected:
        return `Reject organization ${record.name}?`
      default:
        return `Approve organization ${record.name}?`

    }
  }

  const renderContent = () => {
    switch (props.status){
      case OrganizationStatus.Rejected:
        return `Are you sure that you want to reject organization ${record.name}?`
      default:
        return `Are you sure that you want to approve organization ${record.name}?`
    }
  }
  const renderButton = () => {
    switch (props.status){
      case OrganizationStatus.Rejected:
        return `Reject`
      default:
        return `Approve`
    }
  }
  if(!record){
    return null;
  }
  return <div>
    <Button size={'medium'} variant={'contained'} color={props.status === OrganizationStatus.Approved ? 'primary' : 'secondary'} onClick={() => setIsOpen(true)}>{renderButton()}</Button>
    <Confirm
      isOpen={isOpen}
      loading={loading}
      title={renderTitle()}
      content={renderContent()}
      onConfirm={handleSubmit}
      onClose={() => setIsOpen(false)}
    />
  </div>
}
