import React from 'react'
import { Admin, Resource } from 'react-admin'
import Layout from "src/common/layout/Layout";
import { AuthProvider } from "src/common/providers/AuthProvider";

import { dataProvider } from "src/common/providers/dataProvider";
import { history } from "src/history";
import { AdminCreate, AdminEdit, AdminList } from "src/resources/Admin";
import Login from "src/resources/Auth";
import { FeedbackCreate, FeedbackEdit, FeedbackList, FeedbackShow } from "src/resources/Feedback";
import { ProfileEdit, ProfileList, ProfileShow } from "src/resources/Profile";
import {
  ServiceCategoryCreate,
  ServiceCategoryEdit,
  ServiceCategoryList,
  ServiceCategoryShow
} from "src/resources/ServiceCategory";
import { SkillEdit, SkillList } from "src/resources/Skill";
import { TaskEdit, TaskList, TaskShow } from "src/resources/Task";
import { TaskNegotiationEdit, TaskNegotiationList } from "src/resources/TaskNegotiation";
import { UserEdit, UserList } from "src/resources/User";
import customSagas from './sagas'
import './index.css'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { theme } from 'src/style'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FeedbackIcon from '@material-ui/icons/Feedback';
import AssistantIcon from '@material-ui/icons/Assistant';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WorkIcon from '@material-ui/icons/Work';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {BannerCreate, BannerEdit, BannerList} from "./resources/Banner";
import {ChatList, ChatShow} from "./resources/Chat";
import {PushCreate, PushEdit, PushList} from "./resources/Push";
import OrganizationList from 'src/resources/organization/OrganizationList'
import organizations from 'src/resources/organization'
const convertFileToBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.rawFile)

  reader.onload = () => resolve(reader.result)
  reader.onerror = reject
})

const App = () => {
  return (
      <Admin
          theme={theme}
          history={history()}
          dataProvider={dataProvider}
          authProvider={AuthProvider}
          customSagas={customSagas}
          loginPage={Login}
          layout={Layout}
      >
        <Resource
          name="service-category"
          list={ServiceCategoryList}
          show={ServiceCategoryShow}
          create={ServiceCategoryCreate}
          edit={ServiceCategoryEdit}
          icon={SettingsApplicationsIcon}
          options={{ label: 'Services', hidden: false }}
        />

        <Resource
            name="user"
            list={UserList}
            edit={UserEdit}
            icon={EmojiPeopleIcon}
            options={{ label: 'Users', hidden: false }}
        />
        <Resource
            name="profile"
            list={ProfileList}
            edit={ProfileEdit}
            show={ProfileShow}
            icon={AccountCircleIcon}
            options={{ label: 'Profiles', hidden: false }}
        />
        <Resource
            name="skill"
            list={SkillList}
            edit={SkillEdit}
            icon={WorkIcon}
            options={{ label: 'Skills', hidden: false }}
        />

        <Resource
            name="task"
            list={TaskList}
            edit={TaskEdit}
            show={TaskShow}
            icon={AssignmentIcon}
            options={{ label: 'Tasks', hidden: false }}
        />
        <Resource
            name="feedback"
            list={FeedbackList}
            edit={FeedbackEdit}
            create={FeedbackCreate}
            icon={FeedbackIcon}
            options={{ label: 'Feedback', hidden: false }}
        />
        <Resource
            name="location"
            options={{ hidden: true }}
        />
        <Resource
            name="chat-message"
            options={{ hidden: true }}
        />
        <Resource
            name="task-negotiation"
            list={TaskNegotiationList}
            edit={TaskNegotiationEdit}
            icon={AssistantIcon}
            options={{ label: 'Offers', hidden: false }}
        />
        <Resource
            name="chat"
            list={ChatList}
            show={ChatShow}
            icon={QuestionAnswerIcon}
            options={{ label: 'Chats', hidden: false }}
        />
        <Resource
            name="admin"
            list={AdminList}
            create={AdminCreate}
            edit={AdminEdit}
            icon={EmojiPeopleIcon}
            options={{ label: 'Admins', hidden: false }}
        />

        <Resource
          name="banner"
          list={BannerList}
          create={BannerCreate}
          edit={BannerEdit}
          icon={PictureInPictureIcon}
          options={{ label: 'Banners', hidden: false }}
        />
        <Resource
          name="organization-approved"
          {...organizations}
        />
        <Resource
          name="organization-moderation"
          {...organizations}
        />
        <Resource
            name="push"
            list={PushList}
            create={PushCreate}
            edit={PushEdit}
            icon={NotificationsIcon}
            options={{ label: 'Push уведомления', hidden: false }}
        />

      </Admin>
  )
}

export default App
