import { parse, format } from 'date-fns'

export function strToDate(str: string): Date {
  if (str) {
    return parse(str, 'yyyy-MM-dd', new Date())
  } else {
    return null
  }
}

export function dateToStr(date: Date): string {

  if (date) {
    try {
      return format(date, 'yyyy-MM-dd')
    }catch(e){
      return null;
    }
  } else {
    return null
  }
}

export function timeToDate(time: string): Date | null{
  if(!time){
    return null
  }
  const timeParts = time.split(":"); // replace with ":" for differently displayed time.
 if(timeParts.length < 2){
   return null;
 }
  const date = new Date();
  date.setHours(parseInt(timeParts[0], 10));
  date.setMinutes(parseInt(timeParts[1], 10));
  date.setSeconds(0);
  return date;
}

export function timeToAmPm(time: string) {
  if (!time) {
    return ''
  }
  let hour = (time.split(':'))[0]
  let min = (time.split(':'))[1]
  const part = parseInt(hour, 10) > 12 ? 'PM' : 'AM'

  min = (min + '').length === 1 ? `0${min}` : min
  hour = parseInt(hour, 10) > 12 ? (parseInt(hour, 10) - 12).toString() : hour
  hour = (hour + '').length === 1 ? `0${hour}` : hour
  return (`${hour}:${min} ${part}`)
}
