import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
const StatusMap = {
  draft: 'Draft',
  published: 'Published',
  negotiation: 'Negotiation',
  paused: 'Paused',
  canceled: 'Canceled',
  in_progress: 'In Progress',
  done: 'Done'
};
const FeedBackRateField = ({ source, record = {} }) => {

  switch (record['target']) {
    case 'site':
      return <Typography variant={'body2'}>{`${record['usability'] || ''}/${record['masterSearchSpeed'] || ''}/${record['willUseAgain'] || ''}/${record['willReccommend'] || ''}`}</Typography>
      break;
    default:
      return <Typography variant={'body2'}>{`${record['workQuality']  || ''}/${record['politeness'] || ''}/${record['deadlines'] || ''}/${record['extraExpenses'] || ''}/${record['reccommend'] || ''}`}</Typography>

  }
 }
FeedBackRateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}
FeedBackRateField.defaultProps = {
};

export default FeedBackRateField
