import React from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  BooleanInput,
  ImageField,
  AutocompleteInput,
} from 'react-admin'

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
const AdminFilter = (props) => (
  <Filter {...props}>

      <TextInput source="name||$contL&email||$contL" label={'Search'} alwaysOn={true} resettable={true}/>
      <TextInput source="id" label={'ID'}  alwaysOn={true}/>
    <SelectInput
      source="role"
      label={'Role'}
      resettable={true}
      alwaysOn={true}
      choices={[
          { id: 'super_admin', name: 'Super admin' },
          { id: 'admin', name: 'Admin' },
          { id: 'manager', name: 'Manager' },
      ]}
    />

  </Filter>
)

export const AdminList = (props) => (
  <List
    {...props}
    title="Admin list"
    filters={<AdminFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'email', order: 'ASC' }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Admins not found'} description={'You can add an admin'} buttonText={'Create admin'}/>}
  >
    <Datagrid rowClick={'edit'}>
      <TextField source="id" label={'ID'}/>
        <TextField source="name" label={'Name'}/>
        <TextField source="email" label={'Email'}/>
        <TextField source="roleName" label={'Role name'}/>
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Admin: {record.name}
    </span>
  )
}

export const AdminEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
        <TextInput source="email" label={'Email'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="name" label={'Name'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <SelectInput
        source="role"
        variant={'outlined'}
        label={'Role'}
        fullWidth={true}
        choices={[
            { id: 'super_admin', name: 'Super admin' },
            { id: 'admin', name: 'Admin' },
            { id: 'manager', name: 'Manager' },
        ]}
      />
        <TextInput source="setPassword" label={'Password'} variant={'outlined'} fullWidth={true}/>
    </SimpleForm>
  </Edit>
)

export const AdminCreate = (props) => (
  <Create title="Create admin" {...props}>
    <SimpleForm redirect={'list'}>
      <TextInput source="email" label={'Email'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <TextInput source="name" label={'Name'} variant={'outlined'} fullWidth={true} validate={required()}/>


        <BooleanInput
        label={'Send email invite'}
        source="sendInvite"
      />

        <TextInput source="setPassword" label={'Password'} variant={'outlined'} fullWidth={true} validate={required()}/>

      <SelectInput
        source="role"
        variant={'outlined'}
        fullWidth={true}
        label={'Role'}
        choices={[
            { id: 'super_admin', name: 'Super admin' },
            { id: 'admin', name: 'Admin' },
            { id: 'manager', name: 'Manager' },
        ]}
        validate={required()}
      />
    </SimpleForm>
  </Create>
)
