import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslate } from "react-admin";

const Picker = ({ PickerComponent, ...fieldProps }) => {

  const {
    options,
    label,
    source,
    format,
    parse,
    resource,
    className,
    providerOptions,
    validate
  } = fieldProps;
  const translate = useTranslate()
  const { input, meta, isRequired } = useInput({ source, format,
    parse,validate });

  const { touched, error, ...rest } = meta;

  useEffect(() => {
    console.log("Rest error", rest, error)
  }, [rest])
  const handleChange = useCallback(value => {
    Date.parse(value) ? input.onChange(value.toISOString()) : input.onChange(null);
  }, []);

  return (
    <div className="picker">
      <MuiPickersUtilsProvider {...providerOptions}>
      <PickerComponent
        {...options}
  label={<FieldTitle
  label={label}
  source={source}
  resource={resource}

  />}
  margin="normal"
        variant={'outlined'}
  error={!!(touched && (translate(error) || error))}
  helperText={touched && (translate(error) || error)}
  className={className}
  value={input.value ? new Date(input.value) : null}
  onChange={date => handleChange(date)}
  onBlur={() => input.onBlur()}
  />
  </MuiPickersUtilsProvider>
  </div>
)
}

Picker.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

Picker.defaultProps = {
  input: {},
  meta: { touched: false, error: false },
  options: {},
  resource: '',
  source: '',
  labelTime: '',
  className: '',
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};

export const DateInput = props => <Picker PickerComponent={DatePicker} {...props} />
export const TimeInput = props => <Picker PickerComponent={TimePicker} {...props} />
export const DateTimeInput = props => <Picker PickerComponent={DateTimePicker} {...props} />
