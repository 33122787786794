import * as React from 'react';

import {
  BooleanInput,
  Datagrid,
  ReferenceInput,
  TabbedForm,
  FormTab,
  required,
  SelectInput,
  TextInput, FormDataConsumer,
} from 'react-admin';
import {useForm} from 'react-final-form'
import {OrganizationStatusList} from 'src/types'

const InfoTab = props => {
  const form = useForm();

  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <SelectInput source="status" label={'Статус'} choices={OrganizationStatusList}
                 variant={'outlined'} fullWidth={true}/>

  </FormTab>)
}

export const OrganizationForm = props => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  );
};
