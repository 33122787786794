import React from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
    Pagination,
    ReferenceManyField,
    ReferenceInput,
    SelectInput,
    EditButton,
    Show,
    TabbedShowLayout,
    TopToolbar,
    Button,
    Tab
} from 'react-admin'
import { parse } from 'query-string'
import { Link, useLocation } from 'react-router-dom';
import IconContentAdd from "@material-ui/icons/Add";
import S3ImageField from "src/components/fields/S3ImageField";
import S3ImageInput from "src/components/inputs/S3FileUpload/S3ImageUpload";
import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import OrderButtons from "src/components/list/OrderButtons";
import RelatedList from "src/components/list/RelatedList";
const ServiceCategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'}/>
  </Filter>
)

export const ServiceCategoryList = (props) => (
  <List
    {...props}
    title="Service Category list"
    filters={<ServiceCategoryFilter/>}
    actions={<ListActions/>}
    sort={{ field: 'sort', order: 'ASC' }}
    filter={{ isMain: true }}
    bulkActionButtons={false}
    empty={<EmptyList title={'Service Category list not found'} description={'You can add a service category'} buttonText={'Create service category'}/>}
  >
    <Datagrid rowClick={'show'}>
      <TextField source="id" label={'ID'}/>
        <S3ImageField source="iconUrl" label="Icon" maxWidth={45}/>
        <TextField source="name_en" label={'Name'}/>
        <TextField source="description_en" label={'Description'}/>

        <OrderButtons source="sort" />
    </Datagrid>
  </List>
)

const CleaningListShowActions = (props) => {
    const {basePath, data} = props
    console.log("Props", props)
    return (
        <TopToolbar>
            <EditButton basePath={basePath} record={data} />
            <Button component={Link}
                to={`${props.basePath}/create?parentId=${props.id}`} label={'Create'}
            >
                <IconContentAdd />
            </Button>

        </TopToolbar>
    )};
export const ServiceCategoryShow = (props) => (
    <Show {...props} title={<TitleEdit/>} actions={<CleaningListShowActions {...props}/>}>
        <TabbedShowLayout>

            <Tab label="subcategories">
                <ReferenceManyField   reference="service-category" target="parentId" addLabel={false}   sort={{ field: 'sort', order: 'ASC' }} pagination={<Pagination />} perPage={50}>
                    <RelatedList
                      rowClick={'show'}
                        emptyTitle={'No subcategories'}
                        createButtonLabel={'Create sub category'}
                        createButtonTo={props.id ? `/service-category/create?parentId=${props.id}` : null}>

                        <TextField source="name_en"  label={'Name (EN)'} />
                        <TextField source="description_en"  label={'Description (EN)'} />
                        <EditButton />
                        <OrderButtons source="sort" />
                    </RelatedList>

                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)
const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Service category:  {record?.parent?.parent ? `${record?.parent?.parent.name_en} -> ` : ''} {record?.parent ? `${record?.parent.name_en} -> ` : ''}{record.name_en}
    </span>
  )
}

export const ServiceCategoryEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
        <S3ImageInput
            source="iconUrl"
            label={'Иконка'}
            uploadOptions={{
                multiple: false
            }}
        >
            <S3ImageField source="src" title="title"/>
        </S3ImageInput>
        <TextInput source="name_ru" label={'Name (RU)'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="name_en" label={'Name (EN)'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="description_ru" label={'Description (RU)'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="description_en" label={'Description (EN)'} variant={'outlined'} fullWidth={true} validate={required()}/>
    </SimpleForm>
  </Edit>
)

export const ServiceCategoryCreate = (props) => {
    const { parentId } = parse(props.location.search)
    const redirect = (basePath, id, data) => parentId ? `/service-category/${parentId}/show`  : `/service-category`;

    return (
  <Create title="Create service category" {...props}>
    <SimpleForm initialValues={{...(parentId ? {parentId} : {})}} redirect={redirect}>
        <ReferenceInput
            label="Parent category"
            source="parentId"
            reference="service-category"
            variant={'outlined'}
            fullWidth={true}
            filter={{ isMain: true }}
        >
            <SelectInput optionText="name_en" />
        </ReferenceInput>
        <S3ImageInput
            source="iconUrl"
            label={'Иконка'}
            uploadOptions={{
                multiple: false
            }}
        >
            <S3ImageField source="src" title="title"/>
        </S3ImageInput>
        <TextInput source="name_ru" label={'Name (RU)'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="name_en" label={'Name (EN)'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="description_ru" label={'Description (RU)'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="description_en" label={'Description (EN)'} variant={'outlined'} fullWidth={true} validate={required()}/>
    </SimpleForm>
  </Create>
)}
