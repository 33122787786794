import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { TaskNegotiationStateMap } from "src/data/taskStatus";

const TaskNegotiationStateField = ({ source, record = {} }) => {
  return record[source] ? <Typography variant={'body2'}>{TaskNegotiationStateMap[record[source]] || record[source]}</Typography> : null
}
TaskNegotiationStateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}
TaskNegotiationStateField.defaultProps = {
};

export default TaskNegotiationStateField
