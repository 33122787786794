import { ReduxState } from 'ra-core'
export interface IRequestData {
  url: string
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET'
  data?: any
  token?: string
  host?: string
}

export interface IResponse {
  data: any
  err: any
}

export interface BaseAction {
  type: string
  payload: any
}

export type ThemeName = 'light' | 'dark'

export interface AppState extends ReduxState {

}

export interface IUser {
  id: number
  name: string
}

export enum OrganizationStatus {
  Moderation = 'moderation',
  Approved = 'approved',
  Rejected = 'rejected',
}

export const OrganizationStatusList = [
  {id: OrganizationStatus.Moderation, name: 'Moderation'},
  {id: OrganizationStatus.Approved, name: 'Approved'},
  {id: OrganizationStatus.Rejected, name: 'Rejected'}
]