import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import LabelIcon from '@material-ui/icons/Label';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
} from 'react-admin';

import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import WorkIcon from '@material-ui/icons/Work'
import AssignmentIcon from '@material-ui/icons/Assignment'
import FeedbackIcon from '@material-ui/icons/Feedback'
import AssistantIcon from '@material-ui/icons/Assistant'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture'
import organizations from 'src/resources/organization'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SubMenu from 'src/common/layout/SubMenu'


type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuMarketing' | 'menuSettings' | 'menuMarketing';

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
    menuMarketing: true,
    menuSettings: true,

  });
  const translate = useTranslate();
  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div className={classes.root}>

      <MenuItemLink
        to={`/service-category`}
        primaryText="Services"
        leftIcon={<SettingsApplicationsIcon/>}
        dense={dense}
      />
      <MenuItemLink
        to={`/user`}
        primaryText="Users"
        leftIcon={<EmojiPeopleIcon/>}
        dense={dense}
      />
      <MenuItemLink
        to={`/skill`}
        primaryText="Skills"
        leftIcon={<WorkIcon/>}
        dense={dense}
      />
      <MenuItemLink
        to={`/task`}
        primaryText="Tasks"
        leftIcon={<AssignmentIcon/>}
        dense={dense}
      />
      <MenuItemLink
        to={`/feedback`}
        primaryText="Feedback"
        leftIcon={<FeedbackIcon/>}
        dense={dense}
      />
      <MenuItemLink
        to={`/task-negotiation`}
        primaryText="Offers"
        leftIcon={<AssistantIcon/>}
        dense={dense}
      />
      <MenuItemLink
        to={`/chat`}
        primaryText="Chat"
        leftIcon={<QuestionAnswerIcon/>}
        dense={dense}
      />
      <MenuItemLink
        to={`/admin`}
        primaryText="Admins"
        leftIcon={<EmojiPeopleIcon/>}
        dense={dense}
      />
      <MenuItemLink
        to={`/banner`}
        primaryText="Banners"
        leftIcon={<PictureInPictureIcon/>}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle('menuSales')}
        isOpen={state.menuSales}
        name={'Organizations'}
        icon={<organizations.icon/>}
        dense={dense}
      >
        <MenuItemLink
          to={`/organization-approved`}
          primaryText="Approved"
          leftIcon={<organizations.icon/>}
          dense={dense}
        />
        <MenuItemLink
          to={`/organization-moderation`}
          primaryText="Moderation"
          leftIcon={<organizations.icon/>}
          dense={dense}
        />
      </SubMenu>

      <MenuItemLink
        to={`/push`}
        primaryText="Push"
        leftIcon={<NotificationsIcon/>}
        dense={dense}
      />

    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

export default Menu;