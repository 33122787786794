import React from 'react'
import {
    required,
    List,
    Datagrid,
    Filter,
    SelectInput,
    ReferenceManyField,
    ReferenceField,
    Tab,
    TabbedShowLayout,
    Show,
    Edit,
    Create,
    DateField,
    SimpleForm,
    TextField,
    TextInput,
    FormDataConsumer,
    ReferenceInput,
    BooleanInput,
    ImageField,
    Pagination,
    AutocompleteInput,
} from 'react-admin'
import AvatarField from "src/components/fields/AvatarField";
import BooleanField from "src/components/fields/BooleanField";
import FeedBackRateField from "src/components/fields/FeedBackRateField";
import NameWithImageField from "src/components/fields/NameWithImageField";
import ProfileField from "src/components/fields/ProfileField";
import TaskCategoryField from "src/components/fields/TaskCategoryField";
import TaskNegotiationStateField from "src/components/fields/TaskNegotiationStateField";
import TaskNegotiationTypeField from "src/components/fields/TaskNegotiationTypeField";
import TaskStatusField from "src/components/fields/TaskStatusField";
import DateRangeInput from "src/components/inputs/DateRangeInput";

import ListActions from 'src/components/list/ListActions'
import EmptyList from 'src/components/list/EmptyList'
import RelatedList from "src/components/list/RelatedList";
import Countries from "src/data/countries";

const ProfileFilter = (props) => (
    <Filter {...props}>
      <DateRangeInput source={'createdAt'} sourceStart={'createdAt||$gte'} sourceEnd={'createdAt||$lte'} label="Created"  alwaysOn={true} />

      <TextInput source={'userId'} label={'User ID'} resettable={true} variant={'outlined'}/>
        <TextInput source="phone||$contL&email||$contL&firstName||$contL&lastName||$contL" label={'Search'}
                   alwaysOn={true} resettable={true} variant={'outlined'}/>
        <SelectInput source="role" label={'Role'} variant={'outlined'}
                     choices={[{ id: 'client', name: 'Client' }, { id: 'master', name: 'Master' }, {
                         id: 'volunteer',
                         name: 'Volunteer'
                     }]} alwaysOn={true} />
        <TextInput source="id" label={'ID'} alwaysOn={true} resettable={true} variant={'outlined'}/>

    </Filter>
)

export const ProfileList = (props) => (
    <List
        {...props}
        title="Profile list"
        filters={<ProfileFilter/>}
        actions={<ListActions showCreate={false}/>}
        sort={{ field: 'id', order: 'ASC' }}
        bulkActionButtons={false}
        empty={<EmptyList title={'Profile not found'} description={'You can add a profile'}/>}
    >
        <Datagrid rowClick={'show'}>
            <TextField source="id" label={'ID'}/>
            <NameWithImageField source={'photo'}  sourceImage={'photo'} label={'Name'}/>
            <TextField source="role" label={'Role'}/>
            <TextField source="geoname.name" label={'Location'}/>
            <DateField source="birthday" label={'Birthdate'}/>
            <TextField source="email" label={'Email'}/>
            <TextField source="phone" label={'Phone'}/>
            <DateField source="createdAt" label={'Registered'} showTime={true}/>
        </Datagrid>
    </List>
)


const TitleEdit = ({ record }: any) => {
    return (
        <span>
      Profile: {record.firstName} {record.lastName} #{record.id}
    </span>
    )
}

export const ProfileShow = (props) => (
    <Show {...props} title={<TitleEdit/>}>
        <TabbedShowLayout>
            <Tab label="Info" path="">
                <NameWithImageField source={'photo'} sourceName={'firstName'} sourceImage={'photo'} label={'Name'}/>
              <TextField source="id" label={'ID'}/>
              <TextField source="role" label={'Role'}/>

                <TextField source="geoname.name" label={'Location'}/>
                <DateField source="birthday" label={'Birthdate'}/>
                <TextField source="email" label={'Email'}/>
                <TextField source="phone" label={'Phone'}/>
                <DateField source="createdAt" label={'Registered'} showTime={true}/>
                <DateField source="updatedAt" label={'Last profile update'} showTime={true}/>
              <TextField source="address1" label={'Address 1'}/>
                <TextField source="address2" label={'Address 2'}/>
            </Tab>
            <Tab label="Tasks" path="tasks">
                <ReferenceManyField reference="task" target="profileId" addLabel={false}
                                    sort={{ field: 'id', order: 'DESC' }} pagination={<Pagination/>} perPage={50}>
                    <RelatedList
                        emptyTitle={'Task list empty'}>
                        <TextField source="id" label={'ID'}/>
                        <TextField source="title" label={'Title'}/>
                        <TaskCategoryField source="category" label={'Category'}/>
                        <TaskStatusField source="status" label={'Status'}/>
                        <TextField source="geoname.name" label={'Location'}/>
                        <TextField source="priceType" label={'Price Type'}/>
                        <TextField source="budget" label={'Budget'}/>
                        <TextField source="ratePerHour" label={'Rate'}/>
                        <TextField source="masterRole" label={'Master Role'}/>
                        <DateField source="createdAt" showTime={true} label={'Created'}/>
                    </RelatedList>

                </ReferenceManyField>
            </Tab>
            <Tab label="Offers" path="offers">
                <ReferenceManyField reference="task-negotiation" target="profileId" addLabel={false}
                                    sort={{ field: 'id', order: 'DESC' }} pagination={<Pagination/>} perPage={50}>
                    <RelatedList
                        emptyTitle={'Task offers empty'}>
                        <TextField source="id" label={'ID'}/>
                        <TaskNegotiationTypeField source="type" label={'Type'}/>
                        <TaskNegotiationStateField source="state" label={'State'}/>
                        <TextField source="ratePerHour" label={'Rate'}/>
                        <TextField source="maxWeekHours" label={'Max WH'}/>
                        <TextField source="budget" label={'Budget'}/>
                        <TextField source="estimate" label={'Estimate'}/>
                        <DateField source="createdAt" showTime={true} label={'Created'}/>
                        <ReferenceField label="Task" source="taskId" reference="task" link={'show'}>
                            <TextField source="title"/>
                        </ReferenceField>
                    </RelatedList>

                </ReferenceManyField>
            </Tab>
          <Tab label="Skills" path="skills">
            <ReferenceManyField reference="skill" target="profileId" addLabel={false}
                                sort={{ field: 'id', order: 'DESC' }} pagination={<Pagination/>} perPage={50} >
              <RelatedList
                  emptyTitle={'Skills empty'} rowClick={'edit'}>
                <TextField source="id" label={'ID'}/>
                <TaskCategoryField source="category" label={'Category'}/>
                <TextField source="description" label={'Description'}/>
                <TextField source="ratePerHour" label={'Rate'}/>
                <TextField source="budget" label={'Budget'}/>
                <DateField source="createdAt" showTime={true} label={'Created'}/>
              </RelatedList>

            </ReferenceManyField>
          </Tab>
            <Tab label="Feedback owns" path="feedback-owns">
                <ReferenceManyField reference="feedback" target="fromProfileId" addLabel={false}
                                    sort={{ field: 'id', order: 'DESC' }} pagination={<Pagination/>} perPage={50}>
                    <RelatedList
                        emptyTitle={'No feedback given'}>
                        <TextField source="id" label={'ID'}/>
                        <TextField source="target" label={'Target'}/>
                        <FeedBackRateField source={'rate'} label={'Rating'}/>
                        <TextField source="description" label={'Description'}/>

                        <DateField source="createdAt" showTime={true} label={'Created'}/>
                        <BooleanField source={'showInFirstPage'} label={'Main page'}/>
                        <ReferenceField label="Author" source="fromProfileId" reference="profile" link={'show'}>
                            <ProfileField source="firstName"/>
                        </ReferenceField>
                        <ReferenceField label="To" source="toProfileId" reference="profile" link={'show'}>
                            <ProfileField source="firstName"/>
                        </ReferenceField>
                        <ReferenceField label="Task" source="taskId" reference="task" link={'show'}>
                            <ProfileField source="firstName"/>
                        </ReferenceField>
                    </RelatedList>
                </ReferenceManyField>
            </Tab>
            <Tab label="Feedback about" path="feedback-about">
                <ReferenceManyField reference="feedback" target="toProfileId" addLabel={false}
                                    sort={{ field: 'id', order: 'DESC' }} pagination={<Pagination/>} perPage={50}>
                    <RelatedList
                        emptyTitle={'No feedback given'}>
                        <TextField source="id" label={'ID'}/>
                        <TextField source="target" label={'Target'}/>
                        <FeedBackRateField source={'rate'} label={'Rating'}/>
                        <TextField source="description" label={'Description'}/>

                        <DateField source="createdAt" showTime={true} label={'Created'}/>
                        <BooleanField source={'showInFirstPage'} label={'Main page'}/>
                        <ReferenceField label="Author" source="fromProfileId" reference="profile" link={'show'}>
                            <ProfileField source="firstName"/>
                        </ReferenceField>
                        <ReferenceField label="To" source="toProfileId" reference="profile" link={'show'}>
                            <ProfileField source="firstName"/>
                        </ReferenceField>
                        <ReferenceField label="Task" source="taskId" reference="task" link={'show'}>
                            <ProfileField source="firstName"/>
                        </ReferenceField>
                    </RelatedList>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)

export const ProfileEdit = (props) => (
    <Edit {...props} title={<TitleEdit/>} undoable={false}>
        <SimpleForm redirect={'list'}>
            <TextInput source="role" label={'Role'} variant={'outlined'} fullWidth={true} disabled={true}/>
            <TextInput source="email" label={'Email'} variant={'outlined'} fullWidth={true} validate={required()}/>
            <TextInput source="phone" label={'Phone'} variant={'outlined'} fullWidth={true} validate={required()}/>
            <TextInput source="firstName" label={'First name'} variant={'outlined'} fullWidth={true}
                       validate={required()}/>
            <TextInput source="lastName" label={'Last name'} variant={'outlined'} fullWidth={true}
                       validate={required()}/>
            <AutocompleteInput label="Country" source={'countryCode'} choices={Countries} variant={'outlined'}
                               fullWidth={true}/>

            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <ReferenceInput label="City" source="geonameid" reference="location" variant={'outlined'}
                                    fullWidth={true}
                                    filter={{ country: formData.countryCode || 'CA', fclass: 'P', fcode: 'PPLA' }}
                                    filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText } : {}) })}
                                    sort={{ field: 'population', order: 'DESC' }}>
                        <AutocompleteInput optionText="name" optionValue="geonameid"/>
                    </ReferenceInput>
                )}
            </FormDataConsumer>
            <TextInput source="address1" label={'Address 1'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="address2" label={'Address 1'} variant={'outlined'} fullWidth={true}/>

        </SimpleForm>
    </Edit>
)
